import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/logo.png'

export default () => (
  <nav>
          <div><Link to="/"><img className="navLogo" height="30" src={logo} alt="Kiwok Logo"></img></Link></div>
          <ul className="navLinks" style={{listStyle: 'none'
        }}>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/bodykom">BodyKom</Link></li>
            <li><Link to="/investorRelations">Investor Relations</Link></li>
            <li><Link to="/contact">Contact</Link></li>
            {/*<li style={{fontWeight: "Bold"}}><Link to="/nyemission">Nyemission Q1 2024</Link></li>*/}
            <li style={{fontWeight: "Bold"}}><Link to="/arsstamma">Årsstämma</Link> </li>
          </ul>
          <div className="burger">
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
    </nav>
)

