import React from 'react'
import { Link } from 'gatsby'
import styles from './footer.module.css'
import logo from '../img/logoWhite.png'
import twitter from '../img/twitter.png'
import facebook from '../img/facebook.png'
import linkedin from '../img/linkedin.png'
import youtube from '../img/youtube.png'

export default () => (
  <footer role="footer">
    <div className={styles.wrapperFooter}>
        <div className={styles.wrapperChild}>
            <div>
                <Link to="/"><img className="footerLogo" height="50" width="auto" src={logo} alt="Kiwok logo"></img></Link>
            </div>
            <ul className={styles.footer}>
            <li className={styles.footerItem}>
                <Link to="/bodykom/">BodyKom</Link>
            </li>
            <li className={styles.footerItem}>
                <Link to="/about/">About</Link>
            </li>
            <li className={styles.footerItem}>
                <Link to="/investorRelations/">Investor Relations</Link>
            </li>
            <li className={styles.footerItem}>
                <Link to="/contact/">Contact</Link>
            </li>
            </ul>
        </div>
        <div className={styles.wrapperChild}>
            <p>Kiwok Social Media</p>
            {/*<a href=""><img className={styles.socialmedia} height="25" width="auto" src={twitter} alt="twitter logo"></img></a>*/}
                <a href="https://www.linkedin.com/company/kiwok-nordic-ab-publ/about/"><img className={styles.socialmedia} height="25" width="auto" src={linkedin} alt="linkedin logo"></img></a>
                <a href="https://www.facebook.com/kiwokswe"><img className={styles.socialmedia} height="25" width="auto" src={facebook} alt="facebook logo"></img></a>
                <a href="https://www.youtube.com/channel/UCs5ai8zfAmmSOf7x8arorqg"><img className={styles.socialmedia} height="25" width="auto" src={youtube} alt="youtube logo"></img></a>
        </div>
    </div>
  </footer>
)
